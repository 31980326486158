.cu_header_wrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.cu_address_wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
}

.contact_empty_add {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #307ae9;
  font-size: 14px;
  cursor: pointer;
}

.w-75 {
  width: 75%;
}

.contact_empty_add:hover {
  text-decoration: underline;
}

.contact_section_container {
  padding: 10px;
}

.cu_address_email_wrapper {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
