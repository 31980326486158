.default_chat_room_container {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  height: calc(100vh - 60px);
  margin: 0px 10px;
}

.group_chat_img {
  width: 500px;
  // height: 350px;
  object-fit: cover;
}

.chat_room_default_desc {
  //   font-weight: 600;
  color: #5f5f5f;
  text-align: center;
  font-size: 18px;
}
