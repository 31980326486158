.story_home_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.story_home_container {
  padding: 20px;
}

.backBtn_spacing {
  margin: 20px 0px;
}

.story_template {
  position: relative;
  width: fit-content;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fafafa;
}

@media (max-width: 768px) {
  .home_wrap {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    /* scroll-snap-type: y mandatory; */
  }
}

.story_content_copy_link {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.story_content_actions {
  padding: 40px 14px;
  font-size: 14px;
  font-weight: 500;
}

.story_pop_up_close {
  position: absolute;
  top: 0;
  right: 0;
  margin: 10px;
}

.story_pop_up_views {
  position: absolute;
  top: 0;
  left: 0;
  margin: 10px 14px;
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 16px;
}

.story_template_content_wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 5px;
}

.story_options_spacing {
  margin: 10px 0px;
}

.story_metrics_container {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin: 10px 0px;
}

.story_metrics_wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.story_reaction_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.story_reactions_wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.no_of_views_wrapper {
  display: flex;
  align-items: center;
  gap: 5px;
}

.flex_row_align {
  display: flex;
  align-items: center;
}

.gap_5 {
  gap: 5px;
}

.story_info_pop_up {
  position: absolute;
  background-color: #fafafa;
  top: 20%;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 5px;
  overflow-y: scroll;
  box-shadow: 0px -2px 2px rgba(0, 0, 0, 0.3);
}

.story_info_pop_up_scroll {
  position: absolute;
  transition: top 0.3s ease-in-out;

  background-color: #fafafa;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 5px;
  overflow-y: scroll;
}

.story_template_display_wrapper {
  width: 146px;
  height: 250px;
  border-radius: 5px;
}

.all_stories_templates_wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin: 20px 0px;
}

.story_template_bg_width {
  min-width: 150px;
}

.story_template_img {
  width: 146px;
  height: 250px;
}

.story_template_description {
  padding: 5px 0px;
}

.story_template_nextBtn {
  margin: 20px 0px;
  width: 25%;
}

.story_edit_container {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  align-items: center;
}

.bg_color_story {
  width: 24px;
  height: 24px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.story_publish_btn {
  position: absolute;
  top: 0;
  right: 0;
  // margin: 20px;
}

.story_display_image_wrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 5px;
}

.story_display_wrapper {
  width: 258px;
  height: 456.75px;
  position: relative;
  border-radius: 5px;
  background-position: center;
  background-repeat: no-repeat;
  object-fit: cover;
}

.story_nav_dot_item {
  position: relative;
  width: 24px;
  height: 24px;
  padding: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.story_nav_dot_item {
  border-radius: 5px;
  background-position: center;
  background-repeat: no-repeat;
  object-fit: cover;
}

.selected_nav_dot {
  border: 2px solid #000;
}

.story_nav_dots_container {
  width: 100%;
}

.story_nav_dots {
  width: 100%;
  // overflow-x: auto;
  display: flex;

  gap: 10px;
  justify-content: center;
}

.story_action_item {
  display: flex;
  flex-direction: column;
  gap: 5px;
  justify-content: center;
  align-items: center;
}

.w_container_frame {
  width: 50px;
  text-align: center;
}

.story_content_wrapper {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 75%;
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  color: #fafafa;
  background-image: linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
  border-radius: 0px 0px 5px 5px;
}

.story_title {
  font-weight: 600;
  font-size: 28px;
  border: none;
  outline: none;
  background: none;
}

.story_desc {
  font-weight: 400;
  font-size: 16px;
  border: none;
  outline: none;
  background: none;
}

.action_item_icon {
  padding: 8px;
  border-radius: 9999px;
  border: 1px solid #ccc;
}

.story_actions_container {
  display: flex;
  gap: 30px;
  align-items: flex-start;
}

.slide-up {
  animation-name: slide-up-animation;
  animation-duration: 0.3s;
  animation-timing-function: ease-out;
  transform: translateY(0%);
}

@keyframes slide-up-animation {
  from {
    transform: translateY(200);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.story_link_wrapper {
  padding: 5px 10px;
  display: flex;
  align-items: center;
  gap: 20px;
}

.story_link_item {
  outline: none;
  border: none;
}
