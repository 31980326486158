.profile_menu_wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  text-align: left;
}

.vhive_org_btns {
  // display: flex;
  // flex-direction: column;
  // align-items: center;
  // justify-content: center;
  // gap: 5px;
  text-align: center;
}

.font-ibm {
  font-family: "IBM Plex Sans", sans-serif;
}
