.create_campaign_container {
  padding: 20px;
}

.page_padding {
  padding: 20px;
}

.campaign_nextBtn {
  width: fit-content;
}

.campaign_nextBtn_container {
  display: flex;
  justify-content: flex-end;
}

.camp_created_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  align-items: center;
  padding: 30px 0px;
  gap: 20px;
}

.gap_x_20 {
  gap: 20px;
}

.email_story_link {
  color: black;
  text-decoration: underline;
  cursor: pointer;
}

.uploaded_email_banner_image {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.email_template2_footer {
  margin: 20px 0px 0px 0px;
  background-color: #143a97;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 0px;
  flex-direction: column;
}

.email_footer_socials {
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 10px 0px;
}

.email_copyright_footer {
  padding: 10px 0px;
  color: white;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.email-emp-border {
  border: 1px solid $whitesmoke;
  padding: 10px;
  box-shadow: 0 1px 5px rgb(0 74 116/15%);
}

.email_logo {
  height: 50px;
  object-fit: cover;
  border-radius: 9999px;
}

.email_footer_social_link_wrapper {
  display: flex;
  align-items: center;
}

.empty_input {
  width: 100%;
  resize: none;
  overflow: hidden;
  border: none;
  outline: none;
}

.email_input {
  border: none;
  outline: none;
  width: 100%;
  background-color: transparent;
}

.email_input_footer {
  border: none;
  outline: none;
  background-color: transparent;
}

.copyright_spacer {
  width: 5px;
}

.btn_email_input {
  border: none;
  outline: none;
  cursor: text;
  text-align: center;
  width: 180px;
}

.h3 {
  font-size: 18px;
  font-weight: 700;
}

.h4 {
  font-size: 16px;
  font-weight: 700;
}

.empty_input:focus {
  resize: vertical;
  border: 2px solid #ccc;
  border-radius: 5px;
  padding: 5px;
}
