.plan_wrapper {
  padding: 10px;
  cursor: pointer;
  border-radius: 15px;
  transition: box-shadow 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  //   align-items: center;
  justify-content: space-between;
  width: 225px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
}

.plan_wrapper:hover {
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.2);
}

.plans_home_container {
  padding: 20px;
}

.allplan_templates_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.flexinble_color {
  color: $bright-green;
}

.fw-2 {
  font-weight: 600;
}
.fw-1 {
  font-weight: 500;
}
.fw-2 {
  font-weight: 700;
}

.text-xs {
  font-size: 12px;
}

.all_plans_wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin: 20px 0px;
}

.plan_content_item_wrapper {
  display: flex;
  align-items: center;
  gap: 5px;
  margin: 5px 0px;
}

.plan_buyBtn {
  width: 200px;
  margin: 0px auto;
}

.all_plans_items_wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin: 20px 0px;
}

.buy_plan_container {
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 10px;
}

.buy_plan_container > * {
  flex: 1; /* This allows the items to grow and take up available space */
  width: 50%;
  box-sizing: border-box; /* This ensures the border is included in the width */
}

.card_security_wrapper {
  display: flex;
  align-items: center;
}

.card_security_wrapper > * {
  flex: 1; /* This allows the items to grow and take up available space */
  width: 50%;
  box-sizing: border-box; /* This ensures the border is included in the width */
}

.buy_plan_container > :first-child {
  padding-right: 10px;
  border-right: 1px solid #ccc; /* Adjust the color, width, and style as needed */
}

.stripe_plan_payment_container {
  // padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  gap: 20px;
  // align-items: center;
}

.--r-border10 {
  border-radius: 10px;
}

.my-2 {
  margin: 10px 0px;
}

.plan_content_flex_between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.plan_completed_container {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  text-align: center;
}

.plan_item_header_texts {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.py-10 {
  padding: 40px 0px;
}

.plan_razorpay {
  display: flex;
  flex-direction: column;
  justify-content: end;
  height: 100%;
}

.card_details_item_item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  font-weight: 500;
  border-radius: 10px;
  background-color: $dark-green2;
  color: white;
}

.stripe_change_plan_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 40vh;
}

.cancel_plan_wrapper {
  padding: 10px 0px;
}

.p_pay_card {
  width: 40px;
  height: 30px;
}

.p_pay_card_text_wrapper {
  display: flex;
  align-items: center;
  gap: 5px;
}

.cancel_plan_btns {
  display: flex;
  align-items: center;
  gap: 10px;
}

.cancel_delay_wraper_spacing {
  display: flex;
  flex-direction: column;
  // gap: 2px;
}
