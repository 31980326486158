/*! react-customize-token-input v2.3.0 | (c) 2022 Mark Lin. | MIT | https://github.com/seawind543/react-token-input */

@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Condensed:wght@400;500;600;700&display=swap");

.token-input-container {
  background-color: #fff;
  border: 1px solid #ccc;
  box-sizing: border-box;
  cursor: text;
  height: 130px;
  outline: 0;
  overflow-y: auto;
  // overflow: hidden;
  padding: 0.4em 0.5em;
  font-size: 14px;
  letter-spacing: 0.02em;
  margin: 5px 0px;
}

.token-input-container::-webkit-scrollbar {
  display: block;
  width: 6px;
}

.token-input-container::-webkit-scrollbar-thumb {
  background-color: rgba(55, 55, 55, 0.2);
  border-radius: 10px;
}

.token-input-container::-webkit-scrollbar-track {
  background-color: transparent;
}

.upload_info__content_spacing {
  margin: 20px 0px;
}

.token-input-container *,
.token-input-container :after,
.token-input-container :before {
  box-sizing: inherit;
}
.token-input-container.token-input-container--focused {
  /* border-color: #0096cc; */
}
.token-input-container.token-input-container--errors {
  border-color: #db3d44;
}
.token-input-container .token-input-autosized-wrapper {
  float: left;
  margin-right: 4px;
  // overflow: hidden;
}
.token-input-container .token-input-autosized-wrapper input {
  background: none;
  border: 0;
  box-shadow: none;
  color: #222;
  cursor: text;
  height: 32px;
  margin: 0;
  outline: 0;
  padding: 0;
}
.token-input-container .token-input-token-list .token-input-token {
  cursor: pointer;
  border-radius: 10px;
  align-content: center;
  align-items: center;
  background-color: rgb(249, 250, 251);
  // overflow: hidden;
  border: 0;
  /* color: #0096cc; */
  display: flex;
  flex-flow: row nowrap;
  float: left;
  height: 32px;
  justify-content: center;
  /* line-height: 1.5; */
  margin: 0 4px 4px 0;
  max-width: 100%;
  padding: 0;
  white-space: nowrap;
}
/* .token-input-container .token-input-token-list .token-input-token:hover {
  background-color: #e6f4fc;
} */
.token-input-container
  .token-input-token-list
  .token-input-token.token-input-token--error {
  background-color: #f9b5b5;
  color: #db3d44;
}
/* .token-input-container
  .token-input-token-list
  .token-input-token.token-input-token--error:hover {
  background-color: #ffdada;
} */
/* .token-input-container
  .token-input-token-list
  .token-input-token.token-input-token--read-only:hover {
  background-color: #cceaf5;
} */
/* .token-input-container
  .token-input-token-list
  .token-input-token.token-input-token--read-only.token-input-token--error:hover {
  background-color: #f9b5b5;
} */
/* .token-input-container
  .token-input-token-list
  .token-input-token.token-input-token--editable:hover {
  cursor: pointer;
} */
.token-input-container
  .token-input-token-list
  .token-input-token.token-input-token--active
  .token-input-autosized-wrapper {
  align-content: center;
  align-items: center;
  display: flex;
  height: 32px;
  justify-content: center;
  margin: 4px 8px;
}
.token-input-container
  .token-input-token-list
  .token-input-token.token-input-token--active
  .token-input-autosized-wrapper
  input {
  border-bottom: 1px solid #aaa;
  height: auto;
}
.token-input-container
  .token-input-token-list
  .token-input-token
  .token-input-token__label-wrapper {
  flex: 1 0 0;
  margin: 0 8px;
  // overflow: hidden;
  // text-overflow: ellipsis;
}
.token-input-container
  .token-input-token-list
  .token-input-token
  .token-input-token__delete-button {
  /* border-left: 1px solid #aaa; */
  /* border-radius: 0 4px 4px 0; */
  cursor: pointer;
  display: flex;
  flex: 0 0 0;
  height: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
}
/* .token-input-container
  .token-input-token-list
  .token-input-token
  .token-input-token__delete-button
  .token-input-delete-button__close-icon {
  height: 14px;
  position: relative;
  width: 14px;
} */
/* .token-input-container
  .token-input-token-list
  .token-input-token
  .token-input-token__delete-button
  .token-input-delete-button__close-icon:after, */
.token-input-container
  .token-input-token-list
  .token-input-token
  .token-input-token__delete-button
  .token-input-delete-button__close-icon:before {
  /* border-left: 2px solid #222; */
  color: black;
  content: "x";
  height: 14px;
  padding: 5px;
  /* position: absolute; */
}
/* .token-input-container
  .token-input-token-list
  .token-input-token
  .token-input-token__delete-button
  .token-input-delete-button__close-icon:before {
  transform: rotate(-45deg);
} */
/* .token-input-container
  .token-input-token-list
  .token-input-token
  .token-input-token__delete-button
  .token-input-delete-button__close-icon:after {
  transform: rotate(45deg);
} */
/* .token-input-container
  .token-input-token-list
  .token-input-token
  .token-input-token__delete-button:hover {
  background-color: #aaa;
  opacity: 1;
} */
/* .token-input-container
  .token-input-token-list
  .token-input-token
  .token-input-token__delete-button:hover
  .token-input-delete-button__close-icon:after,
.token-input-container
  .token-input-token-list
  .token-input-token
  .token-input-token__delete-button:hover
  .token-input-delete-button__close-icon:before {
  border-color: #fff;
} */
