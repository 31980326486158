.py-60 {
  padding-top: 60px;
  padding-bottom: 60px;
}

.flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.text-3xl {
  font-size: 3rem;
}

.font-bold {
  font-weight: bold;
}

.text-center {
  text-align: center;
}

.font-normal {
  font-weight: normal;
}

.text-xl {
  font-size: 1.25rem;
}
