.form_list_item {
}

.h_90 {
  height: 90vh;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.form_content_margins {
  margin: 10px 0px;
}

.form_content_margins_btn {
  margin: 20px 0px;
}

.formDetails_popup {
  min-width: 300px;
  padding: 20px;
}

.form_btn {
  width: 150px;
}

.form_backBtn {
  position: absolute;
  top: 20px;
  left: 20px;
}

.form_close {
  position: absolute;
  top: 20px;
  right: 20px;
}

.form_content {
  /* Your initial styles */
  transition: transform 0.5s ease-in-out;
}

.slide-in {
  transform: translateX(0); /* Slide in from left */
}

.slide-out {
  transform: translateX(-100%); /* Slide out to the left */
}

.form_heading_bg {
  // background-color: rgb(0, 46, 163);
  // width: 100%;
  // padding: 20px;
  // color: white;
}
