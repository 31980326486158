.analytics_page_container {
  padding: 10px 20px;
  // cursor: not-allowed;
}

.usage_data_container {
  display: flex;
  gap: 10px;
  align-items: center;
}

.justify_between {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.metrics_backBtn_spacing {
  margin: 20px 0px;
}

.flex_1_of_3 {
  flex: 0 0 20%;
}

.graph_and_details {
  display: flex;
  gap: 10px;
}

.visitors_graph_limit {
  height: 300px;
}

.pc_count_head_container {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.product_count_container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px 0px;
}

.usage_number_content {
  display: flex;
  align-items: flex-end;
  text-align: right;
  width: 100%;
}

.sentiment_content {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  align-items: center;
  gap: 10px;
}

.MuiLinearProgress-colorPrimary .MuiLinearProgress-barColorPrimary {
  background-color: #41c667;
}

.MuiLinearProgress-root {
  border-radius: 9999px;
}

.product_count_header {
  display: flex;
  gap: 5px;
  align-items: center;
}

.black_box {
  background-color: black;
  width: 15px;
  height: 15px;
  border-radius: 2px;
}
.green_box {
  background-color: #41c667;
  width: 15px;
  height: 15px;
  border-radius: 2px;
}

.pc_vw_container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.pc_breif_wrapper {
  margin: 10px 0px;
}

.pc_vw_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: black;
}

.user_action_table_dimension {
  width: 100%;
  margin: 0px 10px;
}

.analytics_table_dimension {
  width: 100%;
  margin: 0px 10px;
}

.vh_analytics_table_wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
}

.ad_bb_spacing {
  margin: 20px 0px 10px 0px;
}

.vh_ad_lp {
  margin: 0px 0px 20px 0px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
