.upload_wrapper {
  border: 2px dashed #cbd5e0;
  border-radius: 0.75rem;
  font-size: 0.875rem;
  transition-property: border-color, background-color, box-shadow;
  transition-duration: 300ms;
  border-spacing: 10px;
}

.border-blue-600 {
  border-color: #2563eb;
}

.border-red-600 {
  border-color: #eb2525;
}

.upload_wrapper:hover {
  border-color: #2563eb;
}

.upload_content_wrapper {
  display: flex;
  align-items: center;
  //   gap: 3rem;
}
.upload_content_banner_wrapper {
  padding: 0px 0px 10px 0px;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  //   gap: 3rem;
}

.upload_text_wrapper {
  text-align: center;
  display: flex;
  justify-content: center;
  width: 100%;
}

.uploaded_image {
  width: 86px;
  height: 110px;
  border-radius: 10px 0px 0px 10px;
  object-fit: cover;
}
.uploaded_banner_image {
  width: 100%;
  height: 170px;
  object-fit: cover;
  border-radius: 10px 10px 0px 0px;
}
