.analytics_page_container {
  padding: 10px 20px;
}

.ai_container {
  padding: 10px;
}

.content_select_image {
  width: 230px;
  height: 145px;
}

.ai_home_container {
  margin: 20px 0px;
}

.justify-center {
  justify-content: center;
}

.ttv_next_content_wrapper {
  position: relative;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin: 20px 0px;
}

.ttv_next_content_wrapper_element {
  position: relative;
  width: 300px;
  height: 530px;
}

.ttv_next_content_description_wrapper {
  position: absolute;
  padding: 20px;
  background-color: #303030;
  border-radius: 8px;
  bottom: 10%;
  left: 50%;
  transform: translate(-50%, 0%);
  max-width: 270px;
  word-break: break-all;
  opacity: 0.8;
  color: white;
}

.ttv_next_content {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 8px;
}

.ai_template_wrapper {
  position: relative;
  width: 146px;
  height: 250px;
  cursor: pointer;
}

.ai_services_wrapper {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 10px;
}

.all_ai_templates {
  display: flex;
  gap: 15px;
  align-items: center;
  padding: 10px 0px 0px 0px;
  flex-wrap: wrap;
}

.ai_template_wrapper_footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0px 0px 0px;
  width: 100%;
  font-size: 12px;
}

.secondaryBtnAi {
  background: #dcdcdc;
  border: 1px solid #ebebeb;
  color: #242424;
  border-radius: 9999px;
  padding: 5px;
  font-weight: 600;
}

.text_xs {
  font-size: 12px;
}

.ai_template_wrapper_container {
  border: 2px solid #ebebeb;
  border-radius: 10px;
  padding: 10px;
}

.ai_template_selected_img {
  position: absolute;
  top: 0;
  right: 0;
  margin: 5px;
}

.ai_home_header_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.ai_template_img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.ai_dz_padding_text {
  padding: 10px;
}

.ttv_text_area {
  resize: none;
  outline: none;
  border: none;
  padding: 10px;
  width: 100%;
  height: 100%;
}

.ttv_text_area_wrapper {
  height: 120px;
  border: 1px solid #ccc;
  border-radius: 10px;
  width: 100%;
  position: relative;
}

.form_group_compile {
  display: flex;
  justify-content: space-between;
}

.bgBlack {
  background-color: black;
}

.generating_topics_loader {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 30vh;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.compile_topics_header_spacing {
  margin: 20px 0px;
}

.topic_form_border {
  border: 1px solid #ccc;
  padding: 14px;
  margin: 20px 0px;
  border-radius: 5px;
}

.character_limit_ttv {
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 5px;
}

.text_right {
  text-align: right;
}

.p-2 {
  padding: 2px;
}

.pdf_upload_checkmark {
  display: flex;
  justify-content: center;
  gap: 10px;
  padding: 20px;
  align-items: center;
}

.content_select_description {
  padding: 10px 5px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-size: 14px;
  color: black;
  width: 230px;
}

.ttv_container {
  margin: 20px 0px;
}

.ftv_columns {
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 10px;
}

.script_mode_ttv {
  display: flex;
  align-items: center;
  gap: 14px;
}
