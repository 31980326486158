.user_page_container {
  padding: 20px;
}

.user_data_container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 15px;
}

.h_n_v-user {
  width: 250px;
}
