.carousel .control-dots .dot {
  background-color: black;
}

.carousel .control-dots .dot.selected {
  background-color: black;
}

.guidedTour {
  width: 700px;
  height: 600px;
}

.relative {
  position: relative;
}

.spot-tour_backBtn {
  transform: duration-300;
  z-index: 50;
  position: absolute;
  top: 5px;
  left: 5px;
}

.spot-tour_nextBtn {
  transform: duration-300;
  z-index: 50;
  position: absolute;
  top: 5px;
  right: 5px;
}

.spot-tour_carousel-item {
  margin: 30px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}

.spot-tour_content {
  margin: 10px 0px;
  width: 80%;
}

@media only screen and (max-width: 702px) {
  .guidedTour {
    width: 500px;
  }
}

@media only screen and (max-width: 502px) {
  .guidedTour {
    width: 390px;
  }
}

@media only screen and (max-width: 502px) {
  .guidedCarousel {
    margin-top: 4rem;
  }
}

.guidedImg {
  width: 20rem;
  object-fit: cover;
}

.guidedImgVid {
  width: 16rem;
  object-fit: cover;
}

@media only screen and (max-width: 702px) {
  .guidedImg {
    width: 15rem;
    object-fit: cover;
  }
  .guidedImgVid {
    width: 12rem;
    object-fit: cover;
  }
}
