.home_page_container {
  // background-color: white;
  border-radius: 15px;
}

.home_media_container {
  position: relative;
  margin-bottom: 3rem;
  border-radius: 15px;
}

.home_banner {
  border-radius: 15px 15px 0px 0px;
  height: 100%;
  width: 100%;
  max-height: 10rem;
  object-fit: cover;
}

.intro_vid-ak-container {
  display: flex;
  justify-content: center;
}

.video_gradient {
  border-radius: 15px;
  position: relative;
  width: 300px; /* Adjust the width to your preference */
  height: 200px; /* Adjust the height to your preference */
  background-image: linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
}

.shoppable_btn {
  text-align: center;
  width: 150px;
}

.video_gradient::before {
  border-radius: 15px;
  content: "";
  position: absolute;
  bottom: 0; /* Adjust the position to place the gradient at the desired point */
  left: 0;
  width: 100%;
  height: 30%; /* Adjust the height to your preference */
  background-image: linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
}

.home_details_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.shoppable_align {
  display: flex;
  align-items: center;
  justify-content: center;
}

.home_logo {
  position: absolute;
  bottom: 0;
  transform: translate(15%, 50%);
  width: 100px;
  height: 100px;
  border-radius: 9999px;
}

.home_page_content {
  padding: 10px 20px;
}

.stat_gapping {
  display: flex;
  align-items: center;
  gap: 5px;
}

.home_insights {
  margin: 10px 0px;
  display: flex;
  gap: 10px;
  // color: #059669;
  font-weight: 500;
}

.home_buttons {
  margin: 16px 0px;
  display: flex;
  gap: 10px;
  align-items: center;
}
.home_btn {
  border: 1px solid black;
  background-color: white;
  border-radius: 9999px;
  padding: 4px 16px;
}

.home_settings_logo {
  width: 20px;
  height: 20px;
}

.home_description {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 20px 0px;
}

.intro_video_container {
  display: flex;
  justify-content: center;
}

.intro_video {
  width: 336px;
  height: 600px;
}

.home_guidelines {
  margin: 20px 0px;
}

.line_break {
  margin: 10px;
}
