.dropzone {
  width: 100%;
}
.dropzone-wrapper {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 2px dashed #cbd5e0;
  border-spacing: 10px;
  border-radius: 0.75rem;
  padding: 5rem 2rem;
  text-align: center;
  font-size: 0.875rem;
  transition: transform 0.3s;
  padding: 200px 50px;
  width: 100%;
  height: 500px;
  transition: transform 0.3s ease-in-out;
}

.dropzone-wrapper:hover {
  border-color: #2563eb;
  transition: transform 0.3s ease-in-out;
}

.dropzone--isActive {
  border-color: #2563eb;
  transition: transform 0.3s;
}

.dropzone--isAccept {
  transition: transform 0.3s;
  border-color: #2563eb;
}
