.create_channel_container {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  position: relative;
}

.input_border {
  border: 1px solid #ccc;
  outline: none;
}

.title_and_limit {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.text_padding {
  padding: 10px;
}

.input_width_full {
  width: 100%;
}

.create_channel_btns {
  // position: absolute;
  // bottom: 0;
  // right: 0;
  display: flex;
  gap: 10px;
}

.flex-end {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}
