.main_container_view_story {
  /* max-width: 600px; */
  // height: 100vh;

  // align-items: stretch;
  // flex-direction: column;
  overflow: hidden;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: #000;
  // position: relative;
}

.story_admin_container {
  display: flex;
  justify-content: space-between;
}

.spacer {
  flex-grow: 2;
}

.view_story_outlet_container {
  flex-grow: 2;
  display: flex;
  justify-content: center;
  align-items: center;
}

.day_wise_segment_viewer {
  max-height: 250px;
  overflow-y: scroll;
}

.segment_analytics_container {
  flex-grow: 1;
  // display: flex;
  // justify-content: center;
  // width: 200px;
}

@media (max-width: 768px) {
  .segment_analytics_container {
    display: none;
  }
  .view_story_outlet_container {
    display: flex;
    justify-content: center;
  }
  .spacer {
    display: none;
  }
  .main_container_view_story {
    display: flex;
    justify-content: center;
    overflow: hidden;
    position: fixed;
    height: 100%;
    width: 100%;
    background-color: #000;
  }
}

.flex-row-center {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.segment_analytics_wrapper {
  width: 283px;
  height: 459px;
  flex: 0 33%;
  background-color: black;
}

.view_story_close_btn {
  position: absolute;
  top: 0;
  right: 0;
  margin: 15px;
}

.App {
  text-align: center;
}

.overlay_screen {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: #000000;
  opacity: 0.4;
}

.story_click_btn {
  font-weight: 800;
  font-style: italic;
  color: whitesmoke;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.home_wrap {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 768px) {
  .home_wrap {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    /* scroll-snap-type: y mandatory; */
  }
}

.black-screen {
  position: relative;
  /* max-width: 650px; */
  width: 520px;
  height: 100%; /* Adjust as needed */
  background-color: black;
  background-size: cover;
  background-position: center;
  object-fit: cover;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  overflow: hidden;
  overflow-y: hidden;
}

.start-button {
  position: absolute;
  padding: 10px 30px;
  margin: 100px;
  background-color: rgb(112, 112, 112);
  border-radius: 60px;
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  color: white;
  border: none;
  cursor: pointer;
  outline: none;
}

.show_more_btn_story_ak {
  /* position: absolute;
  bottom: 10%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 10px 0px; */
  width: fit-content;
  margin: 0px auto;
}

.full_zz {
  z-index: 500000;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.stories_wrapper {
  position: relative;
  width: 55%;
  height: 100%;
  min-height: 90vh;
  /* margin: 20px; */
}

.pause_element {
  z-index: 100000;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  transform: translate(-50%);
  width: 300px;
}

.mute_button {
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 20px;
  z-index: 100000000;
  cursor: pointer;
}

@media (max-width: 768px) {
  .stories_container {
    width: 100%;
    height: auto;
    display: flex;
    align-items: flex-start;
  }
}

.stories_container {
  width: 720px; /* Adjust as needed */
  height: 100%; /* Adjust as needed */
  display: flex;
  align-items: center;
}

.edit_story_container {
  padding: 10px 20px;
}

.story_long_desc_wrapper {
  // height: 30vh;
  width: 80%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  // gap: 10px;
  .long_desc_data {
    padding: 10px;
    background-color: black;
    border-radius: 8px;
    display: list-item;
    width: 100%;
    font-weight: 600;
    display: flex;
    align-items: flex-start;
    gap: 5px;
    font-size: 20px;
    opacity: 0.5;
  }
}

@media (min-width: 2500px) {
  .black-screen {
    width: 700px;
  }
  .stories_wrapper {
    width: 700px;
  }
}

.full_wrap {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
}

.full_app_container {
  /* display: flex;
  align-items: center; */
  width: 100%;
  background-color: #353535;
  /* height: 100vh; */
  /* background-color: blue; */
}

.stories_desc_section {
  flex: 90% 0;
  margin: 50px 0px;
}

.stories_bottom_container {
  left: 0;
  right: 0;
  background: linear-gradient(transparent, rgb(0, 0, 0));
  font-family: "IBM Plex Sans Condensed", sans-serif;
  position: absolute;
  bottom: 0;
  top: 55%;
  padding: 20px;
  display: flex;
  justify-content: space-between;
}
.story_title_wrapper_ak {
  min-width: 100px;
}

.emoji_container_items {
  /* position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  bottom: 40%;
  right: 0;
  padding: 5px;
  margin: 30px 10px; */
  margin: 16px 0px;
}

.emojis_wrapper {
  position: absolute;
  margin-bottom: 20px;
  background-color: white;
  border-radius: 9999px;
  display: flex;
  align-items: center;
  padding: 10px 0px;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
  animation: bounceInFromBottom 0.3s ease-out;
}

@media (max-width: 768px) {
  .stories_title {
    font-size: 16px;
  }
}

.stories_title {
  color: white;
  font-weight: 700;
  font-size: 24px;
}

.stories_desc {
  color: white;
  font-size: 20px;
  /* line-height: 18.75px; */
}

@media (max-width: 768px) {
  .stories_desc {
    font-size: 16px;
  }
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.full_z {
  z-index: 50000;
}

.default_emoji {
  width: 40px;
  height: 40px;
}

.emoji {
  width: 40px;
  height: 40px;
  padding: 5px;
}

@keyframes bounceInFromBottom {
  0% {
    opacity: 0;
    transform: translateY(200px);
  }
  60% {
    transform: translateY(-30px);
  }
  80% {
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.segment_analytics_wrapper {
  height: auto;
  max-height: 100vh;
  overflow-y: scroll;
}

.story_bottom_ak {
  width: 100%;
  position: absolute;
  bottom: 0;
  background: linear-gradient(transparent, black);
  z-index: 9999999;
  padding: 0px 20px 120px 20px;
}

.title_and_emoji_styling {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
