:root {
  --toastify-icon-color-error: white;
  --toastify-icon-color-success: white;
}

.Toastify__toast--success {
  font-family: "IBM Plex Sans", sans-serif;

  background: #5b41c6;
  // background: #41c667;
}
.Toastify__toast--error {
  font-family: "IBM Plex Sans", sans-serif;

  background: #c12e0d;
}
.Toastify__toast-body {
  font-family: "IBM Plex Sans", sans-serif;

  color: white;
}
.Toastify__progress-bar--error {
  background: white;
}
.Toastify__progress-bar--success {
  background: white;
}
.Toastify__close-button {
  color: #fff;
  background: transparent;
  outline: none;
  border: none;
  padding: 0;
  cursor: pointer;
  opacity: 0.7;
  transition: 0.3s ease;
  -ms-flex-item-align: start;
  align-self: flex-start;
}

.font {
  font-family: "IBM Plex Sans", sans-serif;
  letter-spacing: 0.025em;
  font-weight: 500;
}
