.downloads_header {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 10px 20px;
}

.downloads_list_container {
  //   padding: 20px;
  width: 100%;
  height: 100%;
}

.h_full {
  height: 100%;
}

.downloads_content_wrapper {
  display: flex;
  flex-direction: column;
  gap: 5px;
  cursor: pointer;
}

.progress_empty_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  flex-direction: column;
  gap: 10px;
  text-align: center;
  width: 100%;
  height: 100%;
}

.progress_content {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
  padding: 10px 20px;
}

.progress_thumb_img {
  width: 51px;
  height: 75px;
  border-radius: 8px;
}

.progress_content:hover {
  background-color: #f0f0f0;
}

.failed_text {
  color: #ed431d;
}

.success_text {
  color: #086100da;
}

.created_date_downloads {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 14px 10px;
}

.relative {
  position: relative;
}
