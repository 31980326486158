.date_picker_container {
  display: flex;
  align-items: center;
  width: 100%;
}

.date_picker {
  padding: 5px;
  border-radius: 9999px;
  width: 95%;
  outline: none;
  cursor: pointer;
  height: 100%;
  z-index: 1000;
}

.date_picker_wrapper {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  z-index: 500;
}

.down_arrow_wrapper {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  right: 0;
  z-index: 5;
  margin: 0px 26px;
}

.w_full {
  width: 100%;
}

.custom_select_wrapper {
  display: flex;
  width: 100%;
  margin: 20px 0px;
  color: white;
}

.day_wise {
  .MuiSelect-select {
    color: white;
    // background-color: white;
  }
  .MuiInputLabel-root {
    color: white;
  }
}
