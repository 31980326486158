.feed_head {
  display: flex;
  align-items: start;
  gap: 10px;
}
.feed_body {
  margin: 10px 50px;
}
.feed_item {
  display: flex;
  flex-direction: column;
  margin: 30px 50px;
}

.feed_video {
  cursor: pointer;
  width: 300px;
  height: 533px;
  border-radius: 15px;
  object-fit: cover;
}

.feed_body_container {
  display: flex;
  align-items: end;
}

.feed_item_icons {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px 0px;
  gap: 12px;
  // cursor: not-allowed;
}

.feed_item_icon_wrapper {
  cursor: pointer;
  padding: 10px;
  border-radius: 9999px;
  background-color: #ebebeb;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s ease-in-out;
}

.feed_item_icon_wrapper:hover {
  scale: 1.1;
}
