.flat_action_button_container {
  position: absolute;
  top: 0;
  right: 0;
  margin: 10px;
  z-index: 100;
  .flat_action_button {
    display: flex;
    align-items: center;
    padding: 0 5px 0 5px;
    .button_icon {
      font-size: 1rem;
      margin-right: 8px;
      color: $white;
    }
    border-radius: 17px;
    background-color: $dark-green;
    height: 2rem;
    .flat_action_button_label {
      font-family: "Poppins", sans-serif;
      font-weight: 600;
      font-size: 0.5rem;
      line-height: 1.6rem;
      letter-spacing: 1px;
      text-transform: none;
      color: $white;
    }
  }
}
