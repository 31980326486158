.payment_due_container {
  // position: absolute;
  // bottom: 20px;
  // left: 20px;
  // width: 100%;
  background-color: $error;
  // background-color: $dark-green2;

  padding: 10px;
  // color: $bright-green;
  color: white;
  text-align: center;
}

.payment_due_main_container {
  // position: absolute;
  // bottom: 0;
  // width: 100%;
}
