.ai_dropzone_content {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0px;
  gap: 10px;
}

.dropped_ai_image {
  width: 81px;
  height: 99px;
  border-radius: 5px;
}

.dropped_ai_images {
  display: flex;
  align-items: center;
  padding: 10px;
  gap: 5px;
  flex-wrap: wrap;
}
