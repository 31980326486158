.left-aligned-shimmer-loader {
  width: 100%;
  position: relative;
  overflow: hidden;
  padding: 20px;
}

.shimmer {
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    transparent 0%,
    rgba(255, 255, 255, 0.2) 50%,
    transparent 100%
  );
  position: absolute;
  animation: shimmerAnimation 2s infinite;
}

.content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.title-placeholder {
  border-radius: 10px;
  width: 70%;
  height: 20px;
  background-color: #ddd;
  margin-bottom: 10px;
}

.description-placeholder {
  border-radius: 10px;
  width: 60%;
  height: 15px;
  background-color: #ddd;
  margin-bottom: 10px;
}

.video-placeholder {
  border-radius: 10px;
  width: 260px;
  height: 480px;
  background-color: #eee;
}

@keyframes shimmerAnimation {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}
