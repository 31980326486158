.navbar_container {
  background-color: white;
  display: flex;
  justify-content: center;
}
.redirect_container {
  margin-top: 3px;
  background-color: white;
  text-align: center;
  padding: 14px 0px;
  font-weight: 600;
}

.navbar_wrapper {
  background-color: #f5f5f5;
}

.cursor-pointer {
  cursor: pointer;
}

.navbar_content {
  display: flex;
  width: 100%;
  justify-content: space-between;
  max-width: 1240px;
  align-items: center;
}

.navbar_buttons {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 5px 10px;
}

.navbar_btn {
  border: 1px solid black;
  padding: 4px 16px;
  border-radius: 9999px;
}

.hive_logo {
  height: 30px;
  width: 30px;
  margin: 8px 10px;
  border-radius: 9999px;
}

.flex {
  display: flex;
}

.items-center {
  align-items: center;
}

.sortBtn {
  gap: 4px;
}

.logo_section {
  display: flex;
  align-items: center;
}

.hamburger_logo {
  display: none;
}

@media (max-width: 768px) {
  .hamburger_logo {
    display: block;
    padding: 0px 4px 0px 10px;
    z-index: 200;
  }
}

.redirect_btn {
  cursor: pointer;
  color: #307ae9;
}
.redirect_btn:hover {
  text-decoration: underline;
}

.pop_up_header {
  // flex: 33% 0% 0%;
  // height: 50%;
  color: #f5f5f5;
  padding: 1rem 3rem;
  background-color: #242424;
  font-size: 18px;
  font-weight: 600;
}

.pop_up_content {
  padding: 0.5rem 2rem;
}

.redirect_dialogue_container {
  // padding: 2rem 4rem;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.w-full {
  width: 100%;
  justify-content: center;
  margin: 2rem 0rem;
}

.align_btns {
  display: flex;
  align-items: center;
  width: 100%;
}

.w-50 {
  width: 50%;
}
