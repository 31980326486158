.contentmgr_container {
  padding: 20px;
}

.content_int_container {
  // padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 20px 0px;
}

.nextMgrBtn {
  width: 250px;
  margin: 20px 0px 0px 0px;
}

.integration_item_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.integration_content_display {
  display: flex;
  gap: 10px;
  align-items: center;
}

.content_mgr_item {
  padding: 20px;
  border: 2px solid $whitesmoke;
  transition: box-shadow 0.3s ease;
  cursor: pointer;
  border-radius: 10px;
}

.content_mgr_item_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.content_mgr_item_selected {
  // border: 1px solid black;
  transition: box-shadow 0.3s ease;
  // box-shadow: 0 2px 24px rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 24px rgba(0, 0, 0, 0.2);
}

.content_mgr_item:hover {
  box-shadow: 0 2px 4px #50fb81;
}

.contentMgr_items_container {
  padding: 20px;
  display: flex;
  flex-direction: column;
  // gap: 30px;
}

.font_lg {
  font-size: 28px;
  font-weight: 500;
}

.css-zigog8 {
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
}

.css-1ysym28 {
  border-left: 1px solid rgb(204, 204, 204);
  height: 16px;
}

.css-c3455h {
  border-left: 1px solid rgb(204, 204, 204);
  height: 16px;
  border-top-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 1px;
}

.content_trigger_wrap {
  // padding: 1px;
  border: 1px solid black;
  border-radius: 9999px;
  transition: transform 0.3s ease;
}

.content_trigger_wrap:hover {
  transform: scale(1.2);
  cursor: pointer;
}

.content_mgr_header {
  padding: 20px 20px 0px 20px;
}

.item_divider_wrapper {
  display: flex;
  justify-content: center;
  padding: 10px 0px;
}
