.ak_confirmation_modal_container {
  position: relative;
}
.ak_confirmation_modal_wrapper {
  padding: 20px;
}

.confirmation_popup_close {
  position: absolute;
  top: 0;
  right: 0;
  margin: 10px;
}

.btnSpacing {
  margin-top: 20px;
}
