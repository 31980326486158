.notification_page_container {
  padding: 20px;
}

.notification_item_container {
  display: flex;
  gap: 20px;
  align-items: center;
  cursor: pointer;
  padding: 5px;
}

.notification_item_container:hover {
  background-color: $whitesmoke;
}

.notification_user_img {
  width: 50px;
  height: 50px;
}

.notification_items_container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
