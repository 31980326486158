.hamburger_menu_container {
  padding: 0px 0px 0px 10px;
}

.hamburger_menu_container_open {
  animation-name: slide-in;
  animation-duration: 0.5s;
  animation-timing-function: ease-in-out;
}

@keyframes slide-in {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0%);
  }
}
.hamburger_menu_container_close {
  animation-name: slide-out;
  animation-duration: 0.5s;
  animation-timing-function: ease-in-out;
}

@keyframes slide-out {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0%);
  }
}
