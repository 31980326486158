.buy_channel_container {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.cart_details_view {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.checkoutBtn {
  margin: 10px 0px;
  width: 30%;
}

.stripe_header {
  text-align: left;
  padding: 20px 0px;
}

.stripe_plan_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.stripe_payment_container {
  padding: 1rem 2rem;
  width: 400px;
  height: 700px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
}

.card_input_element {
  width: 100%;
  display: flex;
  flex-direction: column;
  // align-items: center;
}

.input_padding {
  padding: 5px;
}

.full-width {
  width: 100%;
}

.stripeBtns {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  width: 100%;
  gap: 20px;
}

.stripeBtn {
  width: 100%;
}
