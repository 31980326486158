.tertiary-bg {
  background-color: $dark-green2;
  border-radius: 10px;
}

.tertiary-color {
  color: $bright-green;
}

.color-white {
  color: white;
}

.site_plans-cta-gap {
  gap: 5px;
}
