.chatrooms_page {
  font-family: "IBM Plex Sans Condensed", sans-serif;

  padding: 18px 16px 20px;
  .title_search_section {
    margin-bottom: 25px;
    display: flex;
    align-items: center;
    //justify-content: space-between;
  }
  .page_title {
    font-family: "IBM Plex Sans", sans-serif;
    font-size: 1.7rem;
    line-height: 1.6rem;
    letter-spacing: 0.1rem;
    color: black;
    margin-right: 1rem;
    margin-left: 0.5rem;
  }
  .input_search_container {
    flex: 1;
    margin-right: 3rem;
  }
  .chatrooms_subtitle_container {
    margin-bottom: 24px;
    display: flex;
  }
  .chatrooms_subtitle {
    font-size: 1.5rem;
    line-height: 1.8rem;
    color: $black;
    font-weight: 600;
  }
}

.selected_chatroom_item_container {
  background-color: $whitesmoke;
}

.chatroom_item_container:hover {
  background-color: $whitesmoke;
}

.chatroom_item_container {
  padding: 20px 10px;
  display: flex;
  align-items: center;
  width: 100%;
  // margin-bottom: 26px;
  // margin-top: 20px;
  // max-height: 72px;
  // overflow: hidden;
  font-family: "IBM Plex Sans Condensed", sans-serif;
  text-decoration: none;
  .chat_room_logo_container {
    width: 42px;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 15px;
    background-color: rgba(coral, 0.3);
    .chat_room_logo {
      width: 100%;
      height: auto;
      object-fit: contain;
    }
  }
  .content_container {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow: hidden;
    margin-right: 12px;
    .chatroom_item_title {
      font-size: 1rem;
      line-height: 1rem;
      color: $black;
      margin-bottom: 10px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: 600;
    }
    .chatroom_description {
      font-size: 1rem;
      line-height: 1.3rem;
      color: $black;
      max-height: 3.9rem;
      overflow: hidden;
      display: block; /* Fallback for non-webkit */
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3; /* no of lines */
      text-overflow: -o-ellipsis-lastline;
    }
  }
  .pinned_container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    .chatroom_onlinecount {
      font-size: 1.1rem;
      line-height: 1.4rem;
      color: $light-green2;
      margin-bottom: 20px;
    }
    .chatroom_pin_button {
      width: 26px;
      height: 26px;
      color: #0e606d;
      border-color: #0e606d;
      border-width: 1px;
      border-style: solid;
      padding: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      align-self: center;
      &.pinned {
        color: $white;
        background-color: #0e606d;
      }
      .button_icon {
        // width: unset;
        // height: unset;
        width: 100%;
        height: auto;
      }
    }
  }
}

.chatrooms_page_container {
  // background-color: white;
  position: relative;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  // height: 90vh;
}

.fixed_back_button {
  // position: absolute;
  position: fixed;
  // margin-top: -20px;
  background-color: white;
  // width: 100%;
  padding: 20px 10px;
  z-index: 150;
}
