.react-multi-email {
  margin: 0;
  max-width: 100%;
  max-height: 140px;
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  outline: 0;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  text-align: left;
  line-height: 1.21428571em;
  padding: 0.4em 0.5em;
  background: #fff;
  /* border: 1px solid rgba(34, 36, 38, 0.15); */
  border: none;
  color: rgba(0, 0, 0, 0.87);
  /* border-radius: 0.28571429rem; */
  -webkit-transition: box-shadow 0.1s ease, border-color 0.1s ease;
  transition: box-shadow 0.1s ease, border-color 0.1s ease;
  font-size: 16px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: flex-start;
  overflow-y: auto;
}
.react-multi-email > span[data-placeholder] {
  display: none;
  position: absolute;
  left: 0.5em;
  top: 0.4em;
  padding: 0.4em;
  line-height: 1.21428571em;
}
.react-multi-email.focused {
  border-color: #85b7d9;
  background: #fff;
}

.react-multi-email.empty > span[data-placeholder] {
  display: inline;
  color: rgb(155, 155, 155);
}
.react-multi-email.focused > span[data-placeholder] {
  display: none;
}

.react-multi-email > input {
  /* width: auto !important; */
  width: 100%;
  outline: none !important;
  border: 0 none !important;
  display: inline-block !important;
  line-height: 1;
  vertical-align: baseline !important;
  padding: 0.4em 0.1em !important;
}

.react-multi-email [data-tag] {
  line-height: 1;
  vertical-align: baseline;
  margin: 0.14285714em;
  background-color: #f3f3f3;
  background-image: none;
  padding: 0.5833em 0.833em;
  color: rgba(0, 0, 0, 0.6);
  text-transform: none;
  font-weight: 600;
  border: 0 solid transparent;
  border-radius: 0.28571429rem;
  -webkit-transition: background 0.1s ease;
  -o-transition: background 0.1s ease;
  transition: background 0.1s ease;
  font-size: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  max-width: 100%;
}
.react-multi-email [data-tag] [data-tag-item] {
  max-width: 100%;
  overflow: hidden;
}
.react-multi-email [data-tag]:first-child {
  margin-left: 0;
}
.react-multi-email [data-tag] [data-tag-handle] {
  margin-left: 0.833em;
  cursor: pointer;
}

.multi_email_tag {
  margin: 0.5rem;
  background-color: #f9fafb;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  border-radius: 0.5rem;
  font-size: 1rem;
  line-height: 1.5rem;
  display: inline-block;
  width: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
}

.multi_email_tag > * + * {
  margin-left: 1rem;
}

.error_message {
  color: rgb(206, 0, 0);
}

.success_message {
  color: rgb(0, 156, 0);
}

.invisible {
  display: none;
}

.import_csv_btn {
  padding: 16px 0px 0px 0px;
  width: 300px;
}

.import_csv_btn:hover {
  color: $bright-green;
}

.csv_import_container_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
