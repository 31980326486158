.coming_soon {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5rem 5rem 0rem 5rem;
}

.coming_soon_content {
  text-align: center;
  padding: 1rem 5rem 5rem 5rem;
}
