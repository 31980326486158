.public_hive_wrapper:hover {
  background-color: #fafafa;
}

.public_hive_details {
  display: flex;
  align-items: center;
}

.public_hive_heading {
  padding: 20px 10px 0px 10px;
}

.public_hive_spacing_content {
  padding: 5px 11px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.public_hive_container {
  max-height: 400px;
  // overflow-y: scroll;
}
