.main_container {
  width: 100%;
  height: 100vh;
  position: relative;
  display: flex;
  overflow: hidden;
  flex-direction: column;
}

.layout_container {
  width: 100%;
  height: 100vh;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #f5f5f5;
  overflow: hidden;
}

.chat_bot_container {
  z-index: 50000000;
  position: absolute;
  bottom: 5%;
  right: 2%;
}

.main_body_container {
  margin-top: 3px;
  width: 100%;
  max-width: 1240px;
  z-index: 100;
}

.chat_page_menu_wrapper {
  display: grid;
  grid-template-columns: repeat(6, minmax(0, 1fr));
  height: calc(100vh - 60px);
}

@media (max-width: 768px) {
  .chat_page_menu_wrapper {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}

.page_container {
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  height: calc(100vh - 60px);
}

@media (max-width: 768px) {
  .page_container {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}

.main_menu_wrapper {
  grid-column: span 1 / span 1;
  overflow-y: auto;
}

.fixed {
  position: fixed;
}

.body_container {
  // flex: 1;
  // margin-bottom: 50px;
  overflow-y: auto;
  max-height: 100vh;
}

@media (max-width: 768px) {
  .main_menu_wrapper {
    display: none;
  }
}

@media (max-width: 768px) {
  .chat_page_menu_wrapper {
    display: none;
  }
}

.black_text {
  color: black;
}

.white_text {
  color: white;
}

.gray_text {
  color: #4a4a4a;
}

.p-page {
  padding: 20px;
}

.p-layout {
  padding: 10px;
}

.fw-0 {
  font-weight: 400;
}

.maintenance_page {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.maintenance_header {
  padding: 20px;
  max-width: 600px;
}

.lottie_holder {
  max-width: 1200px;
  max-height: 600px;
  display: flex;
  justify-content: center;
}

.text-black {
  color: black;
}
