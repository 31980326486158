.main_menu_container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  grid-column: span 1 / span 1;
  margin-right: 10px;
  overflow: scroll;
}

.mobile_main_menu_container {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  padding: 20px 20px;
  background-color: $whitesmoke-light;
}

.menu_item {
  display: flex;
  align-items: center;
  padding: 10px 0px;
  gap: 10px;
  transition: 0.3s;
  font-weight: 600;
}

.menu_item_ak {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
}

.selected_menu {
  color: black;
}

.menu_text {
  color: #4f4f4f;
}

.menu_text:hover {
  color: black;
}

.menu_icon {
  width: 15px;
  height: 15px;
}

.channel_heading {
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
  margin: 20px 10px 0px 10px;
}

.channel_item {
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: black;
}

.channel_item:hover,
.selected_channel_item {
  background-color: #f8f8f8;
}

.admin_menu {
  padding: 10px;
}

.user_menu {
  padding: 10px;
}

.channels_menu {
  // padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.channel_card {
  display: flex;
  align-items: center;
  gap: 8px;
}

.channel_logo {
  border-radius: 4px;
  width: 64px;
  height: 80px;
  filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.08))
    drop-shadow(0px 4px 24px rgba(0, 0, 0, 0.12));
  object-fit: cover;
}

.show_more_btn {
  text-align: center;
  padding: 10px;
}
