.body_container {
  grid-column: span 3 / span 3;
  position: relative;
  // overflow: scroll;
}

.only_body_container {
  grid-column: span 4 / span 4;
  position: relative;
  overflow: scroll;
}
.chats_body_container {
  grid-template-columns: repeat(5, minmax(0, 1fr));
  // grid-column: span 5 / span 5;
  display: flex;
  flex-direction: row;
}

@media (max-width: 768px) {
  .chats_body_container {
    // grid-template-columns: repeat(5, minmax(0, 1fr));
    // grid-column: span 5 / span 5;
    overflow-y: auto;
    max-height: calc(100vh - 60px);
    overflow: scroll;
    display: flex;
    flex-direction: column;
  }
}

.chat_menu_wrapper {
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 1fr));
}

.col-span-2 {
  grid-column: span 2 / span 2;
  flex: 2;
  max-height: calc(100vh - 60px);
  // overflow-y: auto;
  overflow-y: scroll;
}

.col-span-3 {
  grid-column: span 3 / span 3;
  flex: 3;
  overflow-y: auto;
  max-height: calc(100vh - 60px);
  overflow: scroll;
}

.chat_menu_container {
  overflow-y: auto;
  overflow: scroll;
  max-height: calc(100vh - 60px);
  grid-column: span 2 / span 2;
}

@media (max-width: 768px) {
  .body_container {
    grid-column: span 4 / span 4;
  }
}
