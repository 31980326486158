.upload_container {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 10px;
}

.upload_section {
  grid-column: span 1 / span 1;
}

.upload_info {
  padding: 20px;
}

.edit_upload_info {
  padding: 20px;
  height: 100vh;
}

.youtube_upload_link_conatiner {
  display: flex;
  flex-direction: column;
  // align-items: center;
  gap: 10px;
  width: 400px;
}

.video_select_wrapper,
.upload_info {
  height: 90vh;
}

@media (max-width: 768px) {
  .upload_container {
    display: flex;
    flex-direction: column-reverse;
    overflow-y: scroll;
  }
}

.cta_shop_margins {
  padding: 10px 0px;
}

.link_flex_display {
  display: flex;
  gap: 5px;
}

.upload_info {
  overflow: auto;
  overflow-y: scroll;
}

.text_box_container {
  margin: 30px 0px;
}

.text_box {
  height: 100px;
}

.spot_content_drop {
  padding: 20px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.button_rows-edit_video {
  padding: 20px 0px;
  display: flex;
  gap: 10px;
  align-items: center;
  width: 100%;
}

.button_rows {
  position: absolute;
  bottom: 0;
  margin: 20px 0px;
  display: flex;
  gap: 10px;
  align-items: center;
  width: 100%;
}

.video_select_wrapper {
  display: flex;
  justify-content: center;
  // align-items: center;
  padding: 5rem;
}

.vid_upload_tabs_wrapper {
  width: 100%;
}

.video_select_container {
  border-radius: 10px;
}

.half_width {
  width: 50%;
}

.upload_info_container {
  position: relative;
  height: 100%;
}

.video_change_status {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fafafa;
  border: 1px solid #cccccc;
  border-radius: 9999px;
  font-weight: 700;
  font-size: 14px;
  margin: 20px 0px;
  padding: 8px 5px;
}

.video_change_start {
  display: flex;
  align-items: center;
  gap: 2px;
}

.secondary_text {
  color: rgb(156, 155, 155);
}

.video_upload_title {
  width: 100%;
  border: none;
  outline: none;
  font-size: 16px;
}

.video_title_empty {
  width: 100%;
  border: none;
  outline: none;
  font-size: 16px;
}

// .video_title_empty {
//   font-size: 20px;
//   color: #cccccc;
//   width: 100%;
//   border: none;
//   outline: none;
//   font-weight: 600;
// }

.character_limit {
  color: #cccccc;
  font-weight: 600;
  // width: 20%;
}

.upload_headings {
  font-weight: 600;
  font-size: 14px;
  color: #808080;
}

// .popup_dialogue {
//   padding: 4rem 8rem;
//   background-color: white;
// }

.upload_info_margins {
  margin: 10px 0rem;
}

.upload_info_margins_pitch {
  margin: 50px 0px 0px 0px;
}

.ql-editor {
  padding: 5px;
}

.ql-container {
  max-height: 150px;
  overflow-y: scroll;
}

.ql-container::-webkit-scrollbar {
  display: block;
  width: 6px;
}

.ql-container::-webkit-scrollbar-thumb {
  background-color: rgba(55, 55, 55, 0.2);
  border-radius: 10px;
}

.ql-container::-webkit-scrollbar-track {
  background-color: transparent;
}

.upload_title_input {
  border: 1px solid #ccc;
  padding: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
