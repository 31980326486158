.footer_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: black;
  gap: 10px;
  padding: 20px 10px;
}

.footer_ch_section {
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.terms_container {
  display: flex;
  align-items: center;
  gap: 4px;
  color: black;
}

.contact_container {
  display: flex;
  gap: 5px;
}

.hover_underline:hover {
  text-decoration: underline;
}

.text-sm {
  font-size: 14px;
}

.download_container {
  display: flex;
  gap: 10px;
  margin: 10px 10px;
}
